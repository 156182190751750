
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { User } from "@/models/users";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.users.pagination.totalItems)

      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
        }
        await root.$store.dispatch('users/load', queryParams)
        return root.$store.state.users.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes._type",
          title: "Typ",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.firstName",
          title: "Vorname",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.lastName",
          title: "Nachname",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.email",
          title: "Email",
          sortable: true,
        },
      ])

      const titleActions = []

      return {
        titleActions,
        getItems,
        totalItems,
        columns
      }
    }
  })
